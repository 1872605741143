
import React from 'react';
import {FaFacebook,FaLinkedin,FaTwitter,FaYoutube,FaInstagram} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const IndividualNavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark quotenavigation-bar ">
      <div className="container">
      <a className="navbar-brand" href="/">
        <img src="/img/KAK-icon.png" alt="" width="70" height="70" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-around" id="navbarNav">
          {/* Add your navigation links here */}
          <ul className="navbar-nav navLinks">
            <li className="nav-item">
              <a className="nav-link" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/topics">Topics</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/quotes">Quotes</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/get-inspired">Get Inspired</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/authors">Authors</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact-us">Contact</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about">About</a>
            </li>
            
          </ul>
          {/* Add icons or other elements here */}
          <div className="d-flex">
            <Link to='#' className='text-decoration-none text-light'> <FaFacebook  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-light'><FaLinkedin  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-light'><FaTwitter  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-light'>  <FaYoutube  className='fs-3 mx-2'/></Link>
            <Link to='#' className='text-decoration-none text-light'><FaInstagram  className='fs-3 mx-2'/></Link>
           
          </div>
        </div>
      </div>
      
    </nav>
  );
};

export default IndividualNavBar;
