
import React from 'react';


const QuoteBackground = () => {

 




  return (
    <div
    >
      <div className="quote-overlay">
        <div className="container text-center">
          <h1 className="display-4 text-white fw-bold">Quotes</h1>
          {/* <p className="lead text-white">Find and save your favorite quotes</p> */}
        
        
         
        </div>
      </div>
    </div>
  );
};

export default QuoteBackground;
