
import React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton,WhatsappShareButton,TelegramShareButton } from 'react-share';
import {FaFacebook,FaLinkedin,FaTwitter,FaWhatsapp,FaTelegram} from 'react-icons/fa';




const ShareButtons = ({ quoteText }) => {
  const quoteUrl = window.location.href;

  return (
    <div className="d-flex justify-content-around m-3">
      <FacebookShareButton url={quoteUrl} quote={quoteText}>
      <FaFacebook  className='fs-3  text-primary'/>
      </FacebookShareButton>
      <TwitterShareButton url={quoteUrl} title={quoteText}>
      <FaTwitter  className='fs-3  text-primary'/>
      </TwitterShareButton>
      <LinkedinShareButton url={quoteUrl} title={quoteText}>
      <FaLinkedin  className='fs-3 '/>
      </LinkedinShareButton>
      <WhatsappShareButton url={quoteUrl} title={quoteText}>
      <FaWhatsapp  className='fs-3  text-success'/>
      </WhatsappShareButton>
      <TelegramShareButton url={quoteUrl} title={quoteText}>
      <FaTelegram className='fs-3  text-primary'/>
      </TelegramShareButton>
    </div>
  );
};

export default ShareButtons;
