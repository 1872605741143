import React, { useState, useEffect } from 'react';
import storiesData from '../components/stories.json'; // Import your JSON data
import GetInspiredBackground from './GetInspiredBackground';
import QuoteNavBar from '../quotes/QuoteNavBar';
import Footer from './Footer';
import SocialSharingButtons from './SocialSharingButtons';
import { Link } from 'react-router-dom';
import { FaLongArrowAltRight } from 'react-icons/fa'


const ITEMS_PER_PAGE = 1;

const GetInspired = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [stories, setStories] = useState([]);
    const totalPages = Math.ceil(storiesData.length / ITEMS_PER_PAGE);
  
    useEffect(() => {
      // Calculate the starting and ending indices for the current page
      const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
  
      // Get the stories to display on the current page
      const storiesToDisplay = storiesData.slice(startIndex, endIndex);
      setStories(storiesToDisplay);
    }, [currentPage]);
  
    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };
  


  return (
    <div>
        <div className='getinspired-bg'>
    <QuoteNavBar />
    <GetInspiredBackground />
    </div>
    <div className='container'>
    <h5 className='container mt-5 text-center'>Inspirational Stories</h5>
    <hr />
    </div>
    {stories.map((story) => (
      <div key={story.id} className='container shadow-lg bg-body rounded p-3 mb-5'>
        <h3 className='container'>{story.title}</h3>
        <p className='container story-font'>{story.content}</p>
        <SocialSharingButtons url={window.location.href} title={story.title}/>
      </div>
    ))}
    <div className="pagination container ">
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index}
          onClick={() => handlePageChange(index + 1)}
          className={currentPage === index + 1 ? 'active ' : ''}
        >
          {index + 1}
        </button>
      ))}
    </div>
        <div className='d-flex justify-content-around align-items-center m-5'>
          <h6 className='text-danger fw-bold'><Link to='/contact-us' className='text-uppercase text-decoration-none text-danger'>Buy Now</Link><FaLongArrowAltRight className='fs-2 m-2' /></h6>
          <Link to='/contact-us' className='text-uppercase'>Why They Achieve Little - Volume 1</Link>
        </div>
    <hr />
    <br />
    <Footer />
  </div>
  );
};

export default GetInspired;
