import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import quotes from '../quotes/quotes.json';
import ShareButtons from './ShareButtons';
import QuoteBackground from './QuoteBackground';
import Footer from './Footer';
import IndividualNavBar from '../quotes/IndividualNavBar';

const IndividualQuotePage = () => {
  const { quoteId } = useParams();
  const [displayImages, setDisplayImages] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);

  const toggleDisplay = () => {
    setDisplayImages(!displayImages);
  };

  useEffect(() => {
    // Find the quote based on the provided quoteId
    const parsedQuoteId = parseInt(quoteId, 10);
    const selectedQuote = quotes.find((quote) => quote.id === parsedQuoteId);
    
    if (!selectedQuote) {
      console.error('Quote not found');
    } else {
      setSelectedQuote(selectedQuote);
    }
  }, [quoteId]);

  return (
    <div>
      <div className='quote-bg'>
        <IndividualNavBar />
        <QuoteBackground />
      </div>
      <div className="container mt-5">
        <div className="mb-3">
          <button className="btn btn-dark" onClick={toggleDisplay}>
            {displayImages ? 'Switch to Text' : 'Switch to Image'}
          </button>
        </div>
        <div className="row">
          <div className="col-md-3 mb-4">
            <div className="card shadow-lg bg-body rounded">
              {selectedQuote && (
                <div>
                  {displayImages ? (
                    <div>
                      <img
                        src={selectedQuote.image}
                        className="card-img-top"
                        alt=" "
                      />
                      <ShareButtons quoteText={selectedQuote.text} />
                    </div>
                  ) : (
                    <div className="card-body">
                      <p className="card-text">{selectedQuote.text}</p>
                      <ShareButtons quoteText={selectedQuote.text} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <br />
      <Footer />
    </div>
  );
};

export default IndividualQuotePage;
