import React from 'react';





const Author = ({ author }) => {
  return (
    <div className="card bg-body shadow-lg rounded-3">
      <img src={author.image} className="card-img-top" alt={`Author ${author.name}`} />
      <div className="card-body">
        <h5 className="card-title">{author.name}</h5>
        <p className="card-text">{author.bio}</p>
        <div className='float-end '>
          <img src='img/small-circle-image.jpg' className='small-circle-image float-end ' alt='Dr. Kofi Amponsah-Kwatiah' />
            </div>
       
      </div>
    </div>
  );
};

export default Author;
