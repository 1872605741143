// src/components/QuotePage.js
import React from 'react';
import QuoteNavBar from '../quotes/QuoteNavBar';
import Topics from '../quotes/Topics';
import TopicsBackground from './TopicsBackground';
import Footer from './Footer';





const TopicsPage = () => {
  

  return (
   <div>
    <div className='topic-bg'>
    <QuoteNavBar />
    <TopicsBackground />
    </div>
    <Topics />
    <hr />
    <br/>
    <Footer />


   </div>
  );
};

export default TopicsPage;
