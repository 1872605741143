
import React, { useState } from 'react';
import {FiSearch} from 'react-icons/fi'
import SearchModal from './SearchModal';
import { Link } from 'react-router-dom';

const Background = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };




  return (
    <div
      className="background"
      
    >
      <div className="overlay">
        <div className="container text-center">
          <h1 className="display-4 text-white">Inspiration at Your Fingertips</h1>
          <p className="lead text-white">Find and save your favorite quotes</p>
         <form onClick={openModal}>
         <div className="input-group input-group-lg search-bar-width" >
            <input
              type="text"
              className="form-control  "
              placeholder="Search kak quote"
            />
            <button className="btn home-button-bg" type="button">
              <FiSearch className='text-light fs-3' />
            </button>
          </div>
         </form>
         <SearchModal isOpen={isModalOpen} handleClose={closeModal} />
          <br />
          <h6 className='container or'>OR</h6>
          <br/>
         
          <div className='container'>
          <Link to='/quotes'>
          <button className="btn home-button-bg text-light" type="button">
              Explore Quotes
            </button></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Background;
