
import React from 'react';
import {Link} from 'react-router-dom'

const topicsData = [
  {
    title: 'Love',
    image: 'img/love.jpg',
    url:'/topics/love' // Replace with your image path
  },
  {
    title: 'Inspiration',
    image: 'img/inspiration.jpg', 
    url:'/topics/inspiration' // Replace with your image path
  },
  {
    title: 'Success',
    image: 'img/success.jpg', // Replace with your image path
    url:'/topics/success'
  },
  {
    title: 'Friendship',
    image: 'img/friendship.jpg', 
    url:'/topics/friendship'// Replace with your image path
  },
  {
    title: 'Health',
    image: 'img/health.jpg', 
    url:'/topics/health'// Replace with your image path
  },
  {
    title: 'Creativity',
    image: 'img/creativity.jpg', 
    url:'/topics/creativity'// Replace with your image path
  },
  {
    title: 'Happiness',
    image: 'img/happiness.jpg', // Replace with your image path
    url:'/topics/happiness'
  },
  {
    title: 'Wisdom',
    image: 'img/wisdom.jpg', // Replace with your image path
    url:'/topics/wisdom'
  },
  {
    title: 'Positivity',
    image: 'img/positivity.jpg', // Replace with your image path
    url:'/topics/positivity'
  },
  {
    title: 'Anger',
    image: 'img/anger.jpg', // Replace with your image path
    url:'/topics/anger'
  },
  {
    title: 'Forgiveness',
    image: 'img/forgiveness.jpg', // Replace with your image path
    url:'/topics/forgiveness'
  },
  {
    title: 'Leadership',
    image: 'img/leadership.jpg', // Replace with your image path
    url:'/topics/leadership'
  },
  {
    title: 'Growth',
    image: 'img/growth.jpg', // Replace with your image path
    url:'/topics/growth'
  },
  {
    title: 'Nature',
    image: 'img/nature.jpg', // Replace with your image path
    url:'/topics/nature'
  },
  {
    title: 'Humor',
    image: 'img/humor.jpg', // Replace with your image path
    url:'/topics/humor'
  },
  {
    title: 'Parenting',
    image: 'img/parenting.jpg', // Replace with your image path
    url:'/topics/parenting'
  },
  {
    title: 'Gratitude',
    image: 'img/gratitude.jpg', // Replace with your image path
    url:'/topics/gratitude'
  },
  {
    title: 'Kindness',
    image: 'img/kindness.jpg', // Replace with your image path
    url:'/topics/kindness'
  },
  {
    title: 'Education',
    image: 'img/education.jpg', // Replace with your image path
    url:'/topics/education'
  },
  {
    title: 'Courage',
    image: 'img/courage.jpg', // Replace with your image path
    url:'/topics/courage'
  },
  {
    title: 'Innovation',
    image: 'img/innovation.jpg', // Replace with your image path
    url:'/topics/innovation'
  },
  {
    title: 'Business',
    image: 'img/business.jpg', // Replace with your image path
    url:'/topics/business'
  },
  {
    title: 'Family',
    image: 'img/family.jpg', // Replace with your image path
    url:'/topics/family'
  },
  {
    title: 'Fear',
    image: 'img/fear.jpg', // Replace with your image path
    url:'/topics/fear'
  },
  {
    title: 'Time',
    image: 'img/time.jpg', // Replace with your image path
    url:'/topics/time'
  },
  {
    title: 'Work',
    image: 'img/work.jpg', // Replace with your image path
    url:'/topics/work'
  },
  // Add more topics as needed
];

const Topics = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        {topicsData.map((topic, index) => (
          <div key={index} className="col-md-3 mb-4">
          <Link to ={topic.url}>
          <div className="card position-relative bg-body shadow-lg rounded">
              <img src={topic.image} className="card-img-top rounded" alt={`Topic ${index + 1}`} />
              <div className="card-img-overlay d-flex align-items-center justify-content-center">
              <h5 className="card-title text-light topicButton">{topic.title}</h5>
              </div>
            </div>
          
          </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Topics;
