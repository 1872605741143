// src/components/QuotePage.js
import React, { useState, useEffect } from 'react';
import Data from '../quotes/creativity.json'
import ShareButtons from '../components/ShareButtons';
import QuoteBackground from '../components/QuoteBackground';
import QuoteNavBar from '../quotes/QuoteNavBar';
import Footer from '../components/Footer';





const CreativityPage = () => {
  const [quotes, setQuotes] = useState([]);
  const [displayImages, setDisplayImages] = useState(false);
  

  useEffect(() => {
    // Simulate fetching quotes from JSON file
    setQuotes(Data);
  }, []);
  

  const toggleDisplay = () => {
    setDisplayImages(!displayImages);
  };







  return (
   <div>
    <div className='creativity-bg'>
    <QuoteNavBar />
    <QuoteBackground />
    </div>
<div className="container mt-5">
      <div className="mb-3">
        <button className="btn btn-dark" onClick={toggleDisplay}>
          {displayImages ? 'Switch to Text' : 'Switch to Image'}
        </button>
      </div>
      <div className="row">
        {quotes.map((quote, index) => (
          <div key={index} className="col-md-3 mb-4">
            <div className="card shadow-lg bg-body rounded">
              {displayImages ? (
                <div>
                 <img src={quote.image} className="card-img-top" alt={`Quote ${index + 1}`} />
                <ShareButtons quoteText={quote.text} />
                </div>
                
              ) : (
                <div className="card-body">
                  <p className="card-text">{quote.text}</p>
                  <p className="card-text float-end" style={{fontStyle:"italic"}}>{quote.author}</p>
                  <br />
                  <ShareButtons quoteText={quote.text} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>

    <hr />
    <br />
    <Footer />
   </div>
  );
};

export default CreativityPage;
