import React from 'react'
import NavigationBar from './NavBar'
import Background from './Background'

function Home() {
  return (
    <div className='home-bg'>
        <NavigationBar />
        <Background />
    
    </div>
  )
}

export default Home