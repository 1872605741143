import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  WhatsappShareButton,TelegramShareButton
} from 'react-share';
import { BsFillShareFill } from "react-icons/bs";

const SocialSharingButtons = ({ url, title }) => {
  return (
    <div>
       <div className='d-flex justify-content-between align-items-center'>
       <p>share <BsFillShareFill /></p>
       <p>Dr. Kofi Amponsah-Kwatiah</p>
       </div>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={url} title={title}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <TelegramShareButton url={url} title={title}>
        <TelegramIcon size={32} round />
      </TelegramShareButton>
    </div>
  );
};

export default SocialSharingButtons;
