import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Card, Row, Col } from "react-bootstrap"; // Import Card from react-bootstrap
import quotesData from "../quotes/quotes.json";
import topicData from "../quotes/searchData.json";
import { FiSearch } from "react-icons/fi";

const SearchModal = ({ isOpen, handleClose }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = (query) => {
    setSearchQuery(query);

    // Perform the search in both quotes and topics data
    const quoteResults = quotesData.filter(
      (quote) =>
        quote &&
        quote.text &&
        quote.text.toLowerCase().includes(query.toLowerCase())
    );
    const topicResults = topicData.filter(
      (topic) =>
        topic &&
        topic.title &&
        topic.title.toLowerCase().includes(query.toLowerCase())
    );

    // Combine and set the search results
    setSearchResults([...quoteResults, ...topicResults]);

    // Provide search suggestions based on user input
    const suggestions = [
      ...new Set([
        ...quoteResults.map((quote) => quote.text),
        ...topicResults.map((topic) => topic.title),
      ]),
    ];
    setSuggestions(suggestions);
  };

  return (
    <Modal show={isOpen} onHide={handleClose} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>Search KAK Quotes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="input-group mb-3 w-50 container">
            <input
              type="text"
              className="form-control "
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <FiSearch className="text-dark fs-3" />
              </span>
            </div>
          </div>
          {searchQuery && (
            <div>
              <h6 className="text-center m-3">Search Results</h6>
              <Row className="w-100">
                {searchResults.map((result, index) => (
                  <Col key={index} md={6} lg={4} className="mb-2 ">
                    <Card className="bg-body shadow-lg rounded-3">
                      <Link
                        to={
                          result.type === "quote"
                            ? `/quotes/${result.id}`
                            : result.url
                        }
                        className="text-decoration-none"
                      >
                        <Card.Body>
                          {result.type === "quote" ? result.text : result.title}
                        </Card.Body>
                      </Link>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          {suggestions.length > 0 && (
            <div>
              <h6 className="text-center m-3">Suggestions</h6>
              <Row>
                {suggestions.map((suggestion, index) => (
                  <Col key={index} md={6} lg={4} className="mb-2">
                    <Card
                      className="suggestion-card bg-body shadow-lg rounded-3"
                      onClick={() => handleSearch(suggestion)}
                    >
                      <Card.Body>{suggestion}</Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SearchModal;
