import React from 'react';
import Author from '../components/Author';
import AuthorsNavBar from '../quotes/AuthorsNavBar';
import AuthorsBackground from './AuthorsBackground';
import Footer from './Footer';

const authorsData = [
  {
    name: 'Dr. Kofi Amponsah-Kwatiah',
    image: 'img/Dr Amponsah.jpg', // Replace with the author's image path
    bio: "Dr. Kofi Amponsah-Kwatiah is an ex-staff of the Central Bank of Ghana with multidisciplinary background: •PhD in Management •Doctor of Management •Masters in Public Administration •Masters in Business Administration (Project Management Option) •BSc. Land Economy. He is a professional member of the Ghana Institution of Surveyors (MGhIs). Dr. Amponsah-Kwatiah has keen interest in imparting academic and experiential knowledge. He has lectured in: •Management Control Systems •Management Information Systems •Project Management •Human Resource Management •Public Administration, and •Research Methods. Dr. Amponsah-Kwatiah is a former lecturer at the Kings University College, Accra. Currently, he is engaged in research activities - individual and collaborative. Dr. Kofi Amponsah-Kwatiah is also a Bible teacher, lay preacher and personal life management speaker. His presentations motivate and guide people through change processes. Real life issues are fundamental to his teachings on any subject he speaks on. Get inspired with his works and words of wisdom. ",
    // Add social media links or website here
  },
 
  
];

const MeetTheAuthors = () => {
  return (
   <div>
<div className='authors-bg'>
<AuthorsNavBar />
<AuthorsBackground />
</div>
<div className="container mt-5">
      <h2 className='text-center mt-5 mb-5'>Meet the Authors</h2>
      <hr />
      <br />
      <div className="row">
        {authorsData.map((author, index) => (
          <div key={index} className="col-md-4 mb-5 ">
            <Author author={author} />
           
          </div>
        ))}
      </div>
    </div>
<hr />
<br />
<Footer />

   </div>
  );
};

export default MeetTheAuthors;
